import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriberDataAsync } from '../store/slices/common/commonSlice';

const useSubscriberData = ({
  type = null,
  setSubscriberCount = () => {},
}) => {
  const dispatch = useDispatch();
  const [subscriberData, setSubscriberData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const selectedCity = useSelector(({ cities }) => {
    return cities?.selectedCity;
  });

  useEffect(() => {
    if (selectedCity?.id) {
      setIsLoading(true);
      dispatch(
        getSubscriberDataAsync({
          city_id: selectedCity?.id,
          type,
        })
      )
        .then((res) => {
          if (res?.meta?.requestStatus === 'fulfilled') {
            setSubscriberData(res.payload);
            setSubscriberCount(res.payload.total_users);
          }
        })
        .catch((err) => {
          console.error({ err });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedCity?.id]);

  return { subscriberData, isLoading };
};

export default useSubscriberData;
