import dynamic from 'next/dynamic';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { formatSwissNumber } from '../../utils';
import { RONS_TIPS_ROUTE } from '../../utils/routes';
import { Skeleton } from '../components/Common/Skeleton';

const SelectCity = dynamic(
  () => import('../components/SelectCity/SelectCity'),
  {
    loading: () => (
      <div className='relative h-[56px] w-[186px] cursor-default rounded-full border border-gray-300 bg-white bg-opacity-10 pb-2 pl-5 pr-10 pt-2 text-left text-white shadow-2xl focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 sm:text-sm md:h-[56px] md:w-[286px]'></div>
    ),
  }
);

const SubscriberData = dynamic(
  () => import('../components/SubscriberData/SubscriberData'),
  {
    ssr: false,
    loading: () => (
      <div className='mt-[0px] flex h-[60px] justify-center'>
        <div className='flex gap-2'>
          {[1, 2, 3, 4].map((i) => (
            <Skeleton
              key={i}
              className='h-[40px] w-[40px] rounded-full md:h-[60px] md:w-[60px]'
            />
          ))}
        </div>
      </div>
    ),
  }
);

import Footer from '../components/Footer/Footer';
import LandingPageNavBar from '../components/NavBar/NavBar/LandingPageNavBar';
import useLoginRoute from '../hooks/useLoginRoute';
import useMobileDetect from '../hooks/useMobileDetect';
import useSubscriberData from '../hooks/useSubscriberData';
import useTranslation from '../hooks/useTranslation';

export default function Home() {
  const [subscriberCount, setSubscriberCount] = useState(450505);
  const router = useRouter();
  const { city } = router.query;
  const { loginRoute } = useLoginRoute();
  const isMobile = useMobileDetect();
  // const loadingLanguage = useSelector(({ common }) => common?.loadingLanguage);
  const { subscriberData, isLoading } = useSubscriberData({
    setSubscriberCount,
  });
  const lang = useTranslation();

  return (
    <>
      <LandingPageNavBar />
      {/* {loadingLanguage ? (
        <GuestHomePageSkeleton />
      ) : ( */}
      <div className='relative flex min-h-screen w-full justify-center bg-cover bg-no-repeat'>
        <div className='absolute inset-0 h-full w-full'>
          <Image
            src={
              isMobile
                ? '/assets/slider-bg-img-mobile.webp'
                : '/assets/slider-bg-img.webp'
            }
            alt='Background Image'
            layout='fill'
            objectFit='cover'
            priority={true}
            placeholder='blur'
            blurDataURL='data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQdHx0fHRsdHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR3/2wBDAR0XFyAeIRshGxsdIR0hHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR3/wAARCAAIAAoDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAb/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAX/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCdABmX/9k='
          />
        </div>

        {/* Content Overlay */}
        <div className='relative z-10 mx-auto my-[36px] flex w-full max-w-[725px] flex-col items-center text-center antialiased'>
          <div className='mx-[52px] flex w-full flex-col items-center px-4 md:mx-[122px] md:px-0'>
            <div className='mb-[12px] flex h-[102px] items-center justify-center overflow-hidden md:h-[180px]'>
              <h1
                className=' text-[32px] font-bold !leading-[1.2] text-white md:text-[60px] line-clamp-4'
                style={{ lineHeight: '1.2' }}
              >
                <span className='block'>
                  {lang?.common?.bring_yourself ||
                    'Deine Stadt. Dein Leben. Deine Möglichkeiten.'}
                </span>
              </h1>
            </div>

            <div className='flex h-[42px] items-center justify-center overflow-hidden md:h-[60px]'>
              <h2
                className='max-h-[42px] text-[14px] !leading-[1.5] text-white md:max-h-[60px] md:text-[20px]'
                style={{ lineHeight: '1.5' }}
              >
                <span className='block'>
                  {lang?.common?.are_you_curious ||
                    'Willst du Teil der coolsten Community der Schweiz sein? Registrier dich jetzt und hol dir den vollen Zugang zum Stadtleben! 🌆✨'}
                </span>
              </h2>
            </div>

            <div className='mt-[28px] flex h-[56px] w-full justify-center'>
              <Link href={loginRoute}>
                <button className='h-[36px] w-[125px] rounded-[100px] bg-white font-bold text-primary transition-colors hover:bg-gray-100 md:h-[56px] md:w-[285px]'>
                  <span className='block truncate px-2 text-[14px] leading-[1.2] md:text-[16px]'>
                    {lang?.common?.sign_up_login || 'Login/Signup'}
                  </span>
                </button>
              </Link>
            </div>

            <div className='mx-auto mt-[32px] flex h-[90px] max-w-[787px] items-center justify-center overflow-hidden md:h-[105px]'>
              <h3
                className='max-h-[90px] text-[18px] font-semibold !leading-[1.4] text-white md:max-h-[105px] md:text-[35px]'
                style={{ lineHeight: '1.4' }}
              >
                <span className='line-clamp-3 block'>
                  {`${lang?.common?.over || 'Check jetzt ein und werde Teil der grössten urbanen Community der Schweiz mit über'} ${formatSwissNumber(subscriberCount)} ${lang?.common?.members_are_waiting_to_connect_with_you || 'Mitgliedern!'}`}
                </span>
              </h3>
            </div>

            <div className='mt-[16px] flex h-[70px] w-full items-center justify-center'>
              <SubscriberData
                subscriberData={subscriberData}
                isLoading={isLoading}
              />
            </div>

            <div className='mt-[28px] flex h-[56px] w-full items-center justify-center gap-2'>
              <SelectCity context='landing_page' />
              <Link
                href={RONS_TIPS_ROUTE(city)}
                className='flex h-[56px] w-[125px] items-center justify-center rounded-[100px] bg-white font-bold text-primary transition-colors hover:bg-gray-100'
              >
                <span className='block truncate px-2 text-[14px] leading-[1.2] md:text-[16px]'>
                  {lang?.common?.visit || 'Visit'}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
      <Footer />
    </>
  );
}
