import { useSelector } from 'react-redux';
import { cn } from '../../../utils';
import useLoginRoute from '../../hooks/useLoginRoute';

const LoginButton = ({ className }) => {
  const lang = useSelector(({ common }) => common?.language);
  const { redirectToLogin } = useLoginRoute();

  return (
    <button
      onClick={() => {
        localStorage.clear();
        redirectToLogin();
      }}
      className={cn(
        `flex h-[36px] min-w-[139px] items-center justify-center rounded-full bg-black p-2 font-bold text-white`,
        className
      )}
    >
      {lang?.common?.sign_up_login}
    </button>
  );
};

export default LoginButton;
