import { useEffect, useState } from 'react';

function useMobileDetect() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // call the function initially to set the state based on initial viewport size

    return () => {
      // cleanup the event listener on component unmount
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
}

export default useMobileDetect;
