import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCityDetailsFromName } from '../../utils';
import {
  getLanguageAsync,
  setCachedLanguage,
} from '../store/slices/common/commonSlice';

const useTranslation = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { city } = router?.query;
  const lang = useSelector(({ common }) => common?.language);

  const selectedCity = useSelector(({ cities }) => cities?.selectedCity);

  function getCachedLanguage() {
    if (typeof window !== 'undefined') {
      const lng = JSON.parse(localStorage.getItem('language')) || null;
      if (lng !== null) {
        dispatch(setCachedLanguage(lng));
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  const cityId = selectedCity?.id || getCityDetailsFromName(city)?.id;

  function getLanguageFromAPI() {
    if (cityId) {
      dispatch(getLanguageAsync({ city_id: cityId })).then((res) => {
        if (res?.meta?.requestStatus === 'fulfilled') {
          localStorage.setItem('language', JSON.stringify(res?.payload));
        }
      });
    }
  }

  function shouldLanguageChange() {
    const german_cities = [
      'Zürich',
      'Basel',
      'Bern',
      'Zug',
      'Luzern',
      'St. Gallen',
      'Winterthur',
      'Family Zurich',
    ];
    const lng = JSON.parse(localStorage.getItem('language')) || null;
    if (lng !== null) {
      if (city === 'Zurich (EN)' && lng?.Group?.Key !== 'En') return true;
      else if (city === 'Romandie' && lng?.Group?.Key !== 'Fr') return true;
      else if (german_cities.includes(city) && lng?.Group?.Key !== 'De') {
        return true;
      } else return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (shouldLanguageChange()) {
      getLanguageFromAPI();
    } else if (selectedCity?.id && !getCachedLanguage()) {
      getLanguageFromAPI();
    } else {
      getCachedLanguage();
    }
  }, [selectedCity?.id, city]);

  return lang;
};

export default useTranslation;
