import React from 'react';
import { cn } from '../../../../utils';
import Logo from '../../Common/Logo';
import LoginButton from '../LoginButton';

const LandingPageNavBar = () => {
  //   const { scrollDirection } = useScrollDirection();

  return (
    <header className=''>
      <div
        className={cn(
          'z-[99] w-full bg-white transition-all duration-500'
          //   scrollDirection === 'down' ? 'fixed top-[-220px]' : 'fixed top-0'
        )}
      >
        <div className='border-b border-border-mid bg-white'>
          <div className='custom-container flex items-center justify-between py-[16px] md:flex-row'>
            <Logo />
            <LoginButton />
          </div>
        </div>
      </div>
      {/* <div className='h-[56px] md:h-[69px]' /> */}
      {/* <BottomNav /> */}
    </header>
  );
};

export default React.memo(LandingPageNavBar);
