import Link from 'next/link';
import { useRouter } from 'next/router';
import { checkIsAuthenticated } from '../../../utils/authUtils';

const Logo = () => {
  const router = useRouter();
  const { city } = router.query;
  const isAuthenticated = checkIsAuthenticated();

  return (
    <Link
      href={isAuthenticated ? `/rons-tips?city=${city}` : `/?city=${city}`}
      className='relative h-[23.05px] w-[110px] cursor-pointer md:h-[35px] md:w-[167px]'
    >
      <svg
        id='Ebene_1'
        data-name='Ebene 1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 53.98 11.47'
      >
        <defs>
          <style>{'.cls-1{fill:#1a171b;}'}</style>
        </defs>
        <path
          fill='#1a171b'
          d='M395.8,298.62a.15.15,0,0,0-.16.16v4a.32.32,0,0,1-.32.32h-.88a.32.32,0,0,1-.32-.32V292.21a.32.32,0,0,1,.32-.32h2.88a3.34,3.34,0,0,1,1.7,6.24c-.3.17-.42.32-.27.6l1.89,3.9a.31.31,0,0,1-.31.48h-.89a.48.48,0,0,1-.47-.32l-1.89-4a.31.31,0,0,0-.3-.19Zm0-5.2a.15.15,0,0,0-.16.16v3.36a.15.15,0,0,0,.16.16h1.52a1.84,1.84,0,1,0,0-3.68Z'
          transform='translate(-394.12 -291.77)'
        />
        <path
          d='M409.58,295.1a25.66,25.66,0,0,1,0,4.8,3.58,3.58,0,0,1-3.61,3.34,3.53,3.53,0,0,1-3.6-3.34,24.06,24.06,0,0,1,0-4.8,3.59,3.59,0,0,1,3.6-3.33A3.53,3.53,0,0,1,409.58,295.1Zm-5.69,0a24.06,24.06,0,0,0,0,4.8,2,2,0,0,0,2.08,1.81,2,2,0,0,0,2.09-1.81,21.4,21.4,0,0,0,0-4.8,2.11,2.11,0,0,0-4.17,0Z'
          transform='translate(-394.12 -291.77)'
        />
        <path
          d='M418.23,302.79a.33.33,0,0,1-.32.32h-1.33c-.18,0-.26-.05-.34-.27l-3.16-7.93a.06.06,0,0,0-.12,0v7.85a.33.33,0,0,1-.32.32h-.82a.32.32,0,0,1-.32-.32V292.21a.32.32,0,0,1,.32-.32h1.33c.18,0,.26.05.34.28l3.15,7.89a.07.07,0,0,0,.13,0v-7.82a.32.32,0,0,1,.32-.32h.82a.33.33,0,0,1,.32.32Z'
          transform='translate(-394.12 -291.77)'
        />
        <path
          d='M430.5,295.1a26.39,26.39,0,0,1-1,4.8c-.58,1.77-1.65,3.34-4,3.34a3,3,0,0,1-3.22-3.34,24.65,24.65,0,0,1,1-4.8c.59-1.75,1.65-3.33,4-3.33A3,3,0,0,1,430.5,295.1Zm-5.69,0a25.67,25.67,0,0,0-1,4.8,1.58,1.58,0,0,0,1.7,1.81A2.47,2.47,0,0,0,428,299.9a22.83,22.83,0,0,0,1-4.8,1.56,1.56,0,0,0-1.7-1.81A2.47,2.47,0,0,0,424.81,295.1Z'
          transform='translate(-394.12 -291.77)'
        />
        <path
          d='M436.34,298.91l1.62,3.72c.1.22-.08.48-.4.48h-.78c-.26,0-.32-.13-.4-.32l-1.7-4a.24.24,0,0,0-.26-.19h-.85a.23.23,0,0,0-.21.16l-.85,4a.41.41,0,0,1-.38.32h-.88a.26.26,0,0,1-.24-.32l2.24-10.58a.42.42,0,0,1,.39-.32h2.56c2,0,3.08,1.05,3.08,2.73a4.17,4.17,0,0,1-2.55,3.76C436.39,298.53,436.22,298.61,436.34,298.91Zm-1.66-5.49a.18.18,0,0,0-.19.16l-.73,3.36c0,.1,0,.16.13.16h1.52a2.28,2.28,0,0,0,2.34-2.48c0-.93-.76-1.2-1.55-1.2Z'
          transform='translate(-394.12 -291.77)'
        />
        <path
          d='M441.34,302.79a.41.41,0,0,1-.38.32h-.88a.25.25,0,0,1-.24-.32l2.24-10.58a.41.41,0,0,1,.38-.32H445c2,0,3.07,1.05,3.07,2.73a3.94,3.94,0,0,1-3.86,4H442.4a.22.22,0,0,0-.21.16Zm2.16-9.37a.19.19,0,0,0-.19.16l-.72,3.36c0,.1,0,.16.13.16h1.52a2.29,2.29,0,0,0,2.34-2.48c0-.93-.77-1.2-1.55-1.2Z'
          transform='translate(-394.12 -291.77)'
        />
      </svg>
    </Link>
  );
};

export default Logo;
